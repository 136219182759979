<template>
  <div class="game-item">
    <div class="game-item__wrapper" :class="{ maintain: isMaintain }" @click="isPlayNow ? undefined : onClickGame()">
      <div class="game-item__wrapper--hover-area">
        <BaseImg
          class="game-item__image"
          :src="item.img"
          :alt="item.name"
          :border-radius="`${radius ? radius : '16'}`"
          lazy
          @load="onLoadImage"
        />
        <div v-if="isPlayNow && !isMaintain" class="game-item__overlay">
          <div class="inner">
            <button @click="onClickGame()">{{ textPlay }}</button>
            <p v-if="item.demo" @click="onClickGame(true)">Chơi thử</p>
          </div>
        </div>
      </div>
      <div v-if="isLoadedImage && jackpotNumber && !hiddenJacPot" class="game-item__jackpot">
        <client-only>
          <AnimateNumberRolling v-if="jackpotNumber !== 0" :number="jackpotNumber" :show-coin="showCoin" :class="[osType]" />
        </client-only>
      </div>
      <div v-if="showName" class="game-item__game-name">
        <span>{{ item.name }}</span>
      </div>
      <BaseImg v-if="isMaintain" class="game-item__maintain" :src="PATH_IMAGES_ICON + 'tag-maintain.svg'" />
    </div>

    <BaseImg
      v-if="getThumbImage(item)"
      class="game-item__maintain"
      :class="[item?.tags?.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import BaseImg from '~/components/common/base-img.vue'
import AnimateNumber from '~/components/common/animate-number.vue'
import AnimateNumberRolling from '~/components/common/animate-number-rolling.vue'
import { IGameItem } from '~/types/common'
import { PATH_IMAGES_ICON } from '~/constants/path'
const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const isLoadedImage = ref(false)
const osType = ref()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    hiddenJacPot?: boolean
    radius?: string
    textPlay?: string
  }>(),
  {
    showCoin: true,
    showName: false,
    hiddenJacPot: false,
    isPlayNow: true,
    autoWidth: false,
    autoHeight: false,
    radius: '12',
    textPlay: 'Chơi Ngay'
  }
)

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})

const isMaintain = computed<boolean>(() => {
  return props.item?.maintain || props.item?.tags?.toLowerCase() === 'maintenance'
})

const onLoadImage = () => {
  isLoadedImage.value = true
}

const onClickGame = (isDemo: boolean) => {
  navigationCheckLoggedInAndOpenGame(props.item, undefined, isDemo)
}

const getThumbImage = (item: IGameItem) => {
  if (item.tags?.toLowerCase() === 'sự kiện' || item.tags?.toLowerCase() === 'event') {
    return PATH_IMAGES_ICON + 'tag-event.svg'
  }
  if (item.tags?.toLowerCase() === 'new') {
    return PATH_IMAGES_ICON + 'tag-new.svg'
  }
  if (item.tags?.toLowerCase() === 'live') {
    return PATH_IMAGES_ICON + 'tag-live.svg'
  }
  if (item.tags?.toLowerCase() === 'live-stream') {
    return PATH_IMAGES_ICON + 'tag-live-stream.svg'
  }
  if (item.tags?.toLowerCase() === 'hot') {
    return PATH_IMAGES_ICON + 'tag-hot.svg'
  }
  return ''
}
onMounted(() => {
  osType.value = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
})
</script>

<style lang="scss" scoped src="~/assets/scss/components/common/game-item.scss"></style>
